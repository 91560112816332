<style lang="less">
.select-semester {
  .disable {
    opacity: 0.5;
  }
}
</style>
<template>
  <!-- <Row>
  <Col :xs="{ span: 22, offset: 1 }" :lg="{ span: 12, offset: 6 }">-->
  <Form
    :model="formItem"
    ref="newUnitForm"
    :rules="ruleValidate"
    label-position="right"
    @submit.native.prevent
    :label-width="100"
  >
    <!-- <div style="margin: 20px">
      <Button @click="handleBack">返回</Button>
    </div>-->
    <FormItem label="课程名称:" prop="course_name">
      <Input v-model="formItem.course_name" placeholder="请输入课程名称">
        <Icon type="ios-book" :size="16" slot="prefix" />
      </Input>
    </FormItem>
    <FormItem label="选择学校:" prop="campus">
      <Select
        v-model="formItem.campus"
        placeholder="请选择学校"
        disabled
        @on-change="handleCampusSelected"
      >
        <Icon type="ios-school" :size="16" slot="prefix" />
        <Option
          v-for="item in campusList"
          :value="item.value"
          :key="item.value"
          >{{ item.value }}</Option
        >
      </Select>
    </FormItem>
    <FormItem label="选择学院:" prop="faculty">
      <Select v-model="formItem.faculty" placeholder="请选择学院">
        <Option
          v-for="item in facultyList"
          :value="item.value"
          :key="item.value"
          >{{ item.value }}</Option
        >
      </Select>
    </FormItem>
    <FormItem label="课程类型:" prop="course_type">
      <Select v-model="formItem.course_type" placeholder="请选择课程类型">
        <Option
          v-for="(typeName, typeCode) in courseTypes"
          :value="typeCode"
          :key="typeCode"
          :label="typeName"
        >
          {{ typeName }}
          <span> ({{ typeCode }}) </span>
        </Option>
      </Select>
    </FormItem>
    <FormItem label="学期:" prop="semester">
      <Select
        v-model="formItem.semester"
        placeholder="请选择学期"
        class="select-semester"
      >
        <Option
          v-for="(is_active, sem) in semesters"
          :value="sem"
          :key="sem"
          :label="sem"
          :class="{ disable: !is_active }"
        >
          {{ sem }}
          <span v-if="!is_active"> (未激活) </span>
        </Option>
      </Select>
    </FormItem>
    <FormItem label="课程编号:" prop="course_id">
      <Input v-model="formItem.course_id" placeholder="请输入课程编号">
        <Icon type="ios-code" :size="16" slot="prefix" />
      </Input>
    </FormItem>
    <FormItem label="课程代码:" prop="course_code">
      <Input v-model="course_code" disabled placeholder="请输入课程代码">
        <Icon type="ios-code" :size="16" slot="prefix" />
      </Input>
    </FormItem>
    <FormItem label="任课老师:" prop="teacher">
      <Select v-model="formItem.teacher" placeholder="请选择任课老师">
        <Icon type="ios-person" :size="16" slot="prefix" />
        <Option
          v-for="item in teacherList"
          :value="item.username"
          :key="item.value"
          :label="item.value"
          ><span>{{ item.value }}</span>
          <span style="float: right; color: #ccc">{{
            item.username
          }}</span></Option
        >
      </Select>
    </FormItem>
    <FormItem label="课程价格:" prop="price">
      <Input
        v-model="formItem.price"
        placeholder="Enter price"
        prefix="logo-usd"
      ></Input>
    </FormItem>
    <FormItem label="课程描述:" prop="description">
      <mavon-editor
        class="desc-editor"
        v-model="formItem.description"
        :toolbars="descEditorOption.toolbars"
        :ishljs="true"
        :codeStyle="descEditorOption.codeStyle"
        :boxShadow="descEditorOption.boxShadow"
        :imageClick="descEditorOption.imageClick"
        :previewBackground="descEditorOption.previewBackground"
        :scrollStyle="descEditorOption.scrollStyle"
        style="max-height: 350px; min-height: 350px; z-index: 0"
      />
    </FormItem>
    <div style="margin-left: 45%">
      <Button type="primary" @click="handleSubmit('newUnitForm')">提交</Button
      >&nbsp;
      <Button style="margin-left: 30px" @click="handleBack">取消</Button>
    </div>
  </Form>
  <!-- </Col>
  </Row>-->
</template>
<script>
export default {
  data() {
    const validatePrice = (rule, value, callback) => {
      if (value === "" || isNaN(value)) {
        callback(new Error("请输入价格"));
      } else {
        if (value < 10 || value > 10000) {
          callback(new Error("价格区间：10-10000"));
        }
        callback();
      }
    };
    return {
      formItem: {
        campus: "",
        faculty: "",
        course_id: "",
        course_name: "",
        teacher: "",
        price: 0.0,
        description: "",
        semester: "",
        course_type: ""
      },
      semesters: [],
      courseTypes: [],
      campusList: [],
      facultyList: [],
      teacherList: [],
      descEditorOption: {
        toolbars: {
          bold: true, // 粗体
          italic: true, // 斜体
          header: true, // 标题
          underline: true, // 下划线
          mark: true, // 标记
          superscript: true, // 上角标
          quote: true, // 引用
          ol: true, // 有序列表
          link: true, // 链接
          imagelink: false, // 图片链接
          help: true, // 帮助
          code: true, // code
          subfield: true, // 是否需要分栏
          fullscreen: false, // 全屏编辑
          readmodel: false, // 沉浸式阅读
          /* 1.3.5 */
          undo: true, // 上一步
          redo: true,
          save: false,
          trash: false, // 清空
          navigation: false, // 导航目录
          table: true
        },
        scrollStyle: true,
        codeStyle: "atom-one-light",
        imageClick: null,
        previewBackground: "#fff",
        boxShadow: false
      },
      ruleValidate: {
        campus: [
          {
            required: true,
            message: "学校不能为空",
            trigger: "blur"
          }
        ],
        faculty: [
          {
            required: true,
            message: "院系不能为空",
            trigger: "blur"
          }
        ],
        semester: [
          {
            required: true,
            message: "学期不能为空",
            trigger: "blur"
          }
        ],
        course_type: [
          {
            required: true,
            message: "课程类型不能为空",
            trigger: "blur"
          }
        ],
        course_id: [
          {
            required: true,
            message: "课程编号不能为空",
            trigger: "blur"
          },
          {
            type: "string",
            min: 4,
            max: 15,
            message: "课程编号4-15字符",
            trigger: "blur"
          }
        ],
        course_name: [
          {
            required: true,
            message: "课程名不能为空",
            trigger: "blur"
          },
          {
            type: "string",
            min: 4,
            max: 50,
            message: "课程名4-50字符",
            trigger: "blur"
          }
        ],
        teacher: [
          {
            required: true,
            message: "教师不能为空",
            trigger: "blur"
          }
        ],
        price: [
          {
            required: true,
            message: "价格不能为空",
            trigger: "blur"
          },
          // { type: 'number', min: 10, max: 10000, message: 'The price should be a number', trigger: 'blur' },
          { validator: validatePrice, trigger: "blur" }
        ],
        description: [
          {
            required: true,
            message: "描述不能为空",
            trigger: "blur"
          }
          // {
          //   type: "string",
          //   min: 1,
          //   max: 200,
          //   message: "描述长度小于200字符",
          //   trigger: "blur"
          // }
        ]
      }
    };
  },
  computed: {
    course_code: function () {
      if (this.formItem.course_id == "") {
        return "请输入课程编号";
      }
      return (
        this.formItem.course_id +
        this.formItem.course_type +
        this.formItem.semester
      );
    },
    campus: function () {
      return this.$store.getters.getCurrentUser.campus;
    }
  },
  methods: {
    handleCampusSelected(selectedCampus) {
      this.axios.get(`api/campus/${selectedCampus}/faculties`).then(resp => {
        // console.log(resp)
        const faculties = resp.data;
        this.facultyList = faculties.map(faculty => ({ value: faculty }));
      });
    },
    handleSubmit(name) {
      // console.log(this.$refs['newUnitForm']);
      this.$refs[name].validate(valid => {
        if (valid) {
          this.formItem.course_code = this.course_code;
          const price = this.formItem.price * 1;
          // const description = this.formItem.description.replace(/\n/g, "<br/>");
          const description = this.formItem.description;
          const newUnitData = { ...this.formItem, price, description };
          const campus = newUnitData.campus;
          const faculty = newUnitData.faculty;
          this.axios
            .post(
              `api/campus/${campus}/faculty/${faculty}/courses`,
              newUnitData
            )
            .then(resp => {
              const courseName = resp.course_name;
              if (courseName) {
                this.$Message.success(`${courseName}添加成功！`);
              }
            })
            .catch(() => {
              this.$Message.error("此课程已存在!");
            });
          this.$router.push("/manage-units");
        } else {
          this.$Message.error("请检查表单是否正确填写");
        }
      });
    },
    handleBack() {
      this.$router.push("/manage-units");
    }
  },
  mounted: function () {
    this.axios.get("api/campuses").then(resp => {
      // console.log(resp)
      const campuses = resp.data;
      this.campusList = campuses.map(campus => ({ value: campus }));
      //   设置默认值
      if (campuses) {
        //  设置默认的campus
        this.formItem.campus = this.campus;
        //   把得到的 string array  转成  object array
        this.axios.get(`api/campus/${this.campus}/faculties`).then(resp1 => {
          // console.log(resp)
          const facaulties = resp1.data;
          //   把得到的 string array  转成  object array
          this.facultyList = facaulties.map(faculty => ({ value: faculty }));
          //  设置默认的faculty
        });
        this.axios
          .get(`api/campus/${this.formItem.campus}/semesters`)
          .then(resp => {
            this.semesters = resp.data;
            const firstSem = Object.keys(this.semesters)[0];
            if (firstSem != undefined) {
              this.formItem.semester = firstSem;
            }
          });
        this.axios
          .get(`api/campus/${this.formItem.campus}/course_types`)
          .then(resp => {
            this.courseTypes = resp.data;
            const firstType = Object.keys(this.courseTypes)[0];
            if (firstType != undefined) {
              this.formItem.course_type = firstType;
            }
          });
      }
    });
    this.axios.get("api/teachers").then(resp => {
      const teachersData = resp.data;
      this.teacherList = teachersData.map(teacher => ({
        value: teacher.display_name,
        username: teacher.username
      }));
    });
    this.$Message.config({
      top: 50,
      duration: 3
    });
  }
};
</script>
